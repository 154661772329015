<template>
  <BaseButton2
    class="base-modal-button"
    @click="(event) => openModal(modal, event)"
  >
    <slot />
  </BaseButton2>
</template>

<script lang="ts" setup>
interface Props {
  modal: string;
}

defineProps<Props>();

const { openModal } = useModalStore();
</script>
